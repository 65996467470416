<template>
  <div class="homepage-widget">
    <section
      v-for="(section, index) in widget.sections"
      :key="index"
      class="homepage-section"
    >
      <Component
        :is="index === 2 ? AnimatedMouseRevealTrail : 'div'"
        class="media-container"
        :class="{ 'media-container--mouse-trail': index === 2 }"
      >
        <BaseImage
          v-if="section.image"
          class="media"
          :image="section.image"
          :img-sizes="[
            { w: 576, h: 384 },
            { w: 1024, h: 684 },
            { w: 1440, h: 962 }
          ]"
          sizes="(min-width: 768px) 75vw, 100vw"
          :width="section.image.width"
          :height="section.image.height"
        />
        <div v-else class="media" />
        <VideoPlayButton
          :video-id="section.video_video_id"
          :is-vimeo="section.video_is_vimeo"
        />

        <ProductionLineIcon v-if="index === 3" class="production-line-icon" />
      </Component>

      <Component
        :is="animationComponents[index]"
        v-if="animationComponents[index]"
        class="animation"
      />
      <div v-else class="animation animation--placeholder" />

      <TextBox
        class="text-box"
        :title="section.title"
        :description="section.description"
      />
    </section>
  </div>
</template>

<script lang="ts" setup>
import ProductionLineIcon from '@/assets/icons/production-line.svg?component'
import type { PublicHomePageWidget } from '@/service/__generated-api'
import type { Widget } from '@/types/utils'

defineProps<Widget<PublicHomePageWidget>>()

const animationComponents = [
  resolveComponent('AnimatedCloud'),
  resolveComponent('AnimatedFourTiles'),
  resolveComponent('AnimatedEye'),
  resolveComponent('AnimatedBeatMachine')
]

const AnimatedMouseRevealTrail = resolveComponent('AnimatedMouseRevealTrail')
</script>

<style lang="scss" scoped>
.homepage-section {
  position: relative;
}

.media-container {
  position: relative;
  height: 100%;
  aspect-ratio: 360 / 270;

  @include media-up(md) {
    position: absolute;
    right: 0;
    width: 75%;
    aspect-ratio: auto;
  }

  .homepage-section:nth-child(2n) & {
    @include media-up(md) {
      right: auto;
      left: 0;
    }
  }

  .homepage-section:nth-child(4) & {
    @include media-up(md) {
      width: 50%;
    }

    @include media-up(lg) {
      width: 75%;
    }
  }

  &--mouse-trail {
    @include media-up(md) {
      position: absolute !important;
    }
  }
}

.production-line-icon {
  position: absolute;
  bottom: 0;
  width: 25%;
  height: auto;

  @include media-up(md) {
    right: 0;
    width: 12.5%;
  }

  @include media-up(lg) {
    right: calc(100% / 3 - 1px);
    width: 16.8%;
  }
}

.animation {
  position: absolute;
  right: 0;
  width: 33%;
  transform: translateY(-100%);

  @include media-up(md) {
    position: relative;
    right: auto;
    z-index: 1;
    width: 25%;
    transform: none;
  }

  .homepage-section:nth-child(2) & {
    right: 0;
    width: 50%;

    @include media-up(md) {
      width: 25%;
    }
  }

  .homepage-section:nth-child(2n) & {
    @include media-up(md) {
      margin-left: auto;
    }
  }

  .homepage-section:nth-child(3) & {
    right: auto;
  }

  .homepage-section:nth-child(4) & {
    position: relative;
    width: 100%;
    transform: none;

    @include media-up(md) {
      width: 50%;
    }

    @include media-up(lg) {
      width: 25%;
    }
  }

  &--placeholder {
    aspect-ratio: 1/1;
  }
}

.text-box {
  @include media-up(md) {
    width: 50%;
  }

  .homepage-section:nth-child(2n) & {
    @include media-up(md) {
      margin-left: auto;
    }
  }
}
</style>
