<template>
  <AnimatedRive ref="riveComponent" class="rive" src="/rive/eye.riv" />
</template>

<script lang="ts" setup>
import { usePointer, useWindowScroll, watchThrottled } from '@vueuse/core'

import AnimatedRive from '@/components/animated/Rive.vue'

const { x: pointerX, y: pointerY } = usePointer()
const { x: scrollX, y: scrollY } = useWindowScroll()

const riveComponent = ref<InstanceType<typeof AnimatedRive> | null>(null)

watchThrottled(
  [riveComponent, pointerX, pointerY, scrollX, scrollY],
  () => {
    if (!riveComponent.value) return
    const inputs =
      riveComponent.value.rive?.stateMachineInputs('State Machine 1') || []

    const rect = (
      riveComponent.value.$el as HTMLElement
    ).getBoundingClientRect()
    const xInput = inputs.find(input => input.name === 'xAxis')
    const yInput = inputs.find(input => input.name === 'yAxis')
    if (!xInput || !yInput) return

    const maxWidth = window.innerWidth
    const maxHeight = window.innerHeight

    const eyeCenterX = rect.x + rect.width / 2
    const eyeCenterY = rect.y + rect.height / 2

    xInput.value = 50 + ((pointerX.value - eyeCenterX) / maxWidth) * 100
    yInput.value = 50 + ((eyeCenterY - pointerY.value) / maxHeight) * 100
  },
  { throttle: 1000 / 60 }
)
</script>

<style lang="scss" scoped>
.rive {
  aspect-ratio: 1/1;
}
</style>
