<template>
  <div class="beat-machine">
    <div class="controls">
      <button
        type="button"
        class="play-button"
        :disabled="isLoading"
        @click="play"
      >
        <StopIcon v-if="isActive" class="play-button__icon" />
        <PlayIcon v-else class="play-button__icon" />
        <span ref="playBtnText" class="play-button__text">{{
          isActive ? 'Stop' : 'Play'
        }}</span>
      </button>
      <AnimatedBeatMachineSineWave :amplitude="amplitude" />
    </div>

    <div class="chocolate">
      <AnimatedBeatMachineTile
        v-for="index in 9"
        :id="index"
        :key="index"
        :is-playing="sounds[index]?.isPlaying"
        class="tile"
        @toggle="toggleAudio(index)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useTransition } from '@vueuse/core'
import { gsap } from 'gsap'

import PlayIcon from '@/assets/icons/play.svg?component'
import StopIcon from '@/assets/icons/stop.svg?component'

const isActive = ref(false)
const playBtnText = ref<HTMLElement | null>(null)
const sounds = ref<
  {
    audio: HTMLAudioElement
    src: string
    isPlaying: boolean
  }[]
>([])
const isLoading = ref(false)

const amplitude = useTransition(
  computed(() => (isActive.value ? 5 : 0)),
  { duration: 200 }
)

onMounted(() => {
  sounds.value = Array(10)
    .fill(null)
    .map((_, index) => ({
      audio: new Audio(),
      src: `/sounds/sound-${index}.mp3`,
      isPlaying: false
    }))

  sounds.value[0] = {
    audio: new Audio(),
    src: `/sounds/sound-base.mp3`,
    isPlaying: false
  }
})

onUnmounted(() => {
  sounds.value.forEach(sound => sound.audio.pause())
})

const play = async () => {
  if (isLoading.value) return
  const wereSoundsLoaded = !!sounds.value[0].audio.src

  if (!wereSoundsLoaded) {
    isLoading.value = true

    await Promise.all(
      sounds.value.map(
        sound =>
          new Promise(resolve => {
            document.body.appendChild(sound.audio)
            sound.audio.oncanplaythrough = resolve
            sound.audio.onload = resolve
            sound.audio.preload = 'auto'
            sound.audio.src = sound.src
            sound.audio.loop = true
            sound.audio.load()
          })
      )
    )

    isLoading.value = false
  }

  gsap
    .timeline()
    .to(playBtnText.value, {
      scale: 0.7,
      duration: 0.1,
      onComplete: () => {
        isActive.value = !isActive.value

        sounds.value.forEach((sound, index) =>
          sound.audio[
            isActive.value && (sound.isPlaying || index === 0)
              ? 'play'
              : 'pause'
          ]()
        )
      }
    })
    .to(playBtnText.value, { scale: 1, duration: 0.1 })
}

const toggleAudio = (index: number) => {
  const sound = sounds.value[index]
  const shouldPlay = !sound.isPlaying

  sound.isPlaying = shouldPlay
  if (isActive.value) sound.audio[shouldPlay ? 'play' : 'pause']()
  else play()
}
</script>

<style lang="scss" scoped>
.beat-machine {
  display: flex;
  gap: rem(28px);
  align-items: center;
  justify-content: space-evenly;
  padding: rem(25px);
  background: $yellow;

  @include media-up(md) {
    padding: rem(31px);
    padding-left: rem(46px);
  }

  @include media-up(lg) {
    flex-direction: column;
    gap: rem(22px);
    justify-content: flex-start;
    aspect-ratio: 1/1;
    padding: rem(32px);
    padding-top: rem(47px);
  }

  @include media-up(xl) {
    padding: rem(73px) rem(46px);
  }
}

.controls {
  display: flex;
  flex-direction: column;
  gap: rem(20px);
  align-items: center;

  @include media-up(lg) {
    flex-direction: row;
    gap: rem(43px);
  }

  @include media-up(xl) {
    gap: rem(50px);
  }
}

.play-button {
  @include font-size(
    (
      xs: 21px,
      xl: 27px
    ),
    1.66
  );
  position: relative;
  display: flex;
  gap: rem(10px);
  align-items: center;
  font-weight: 600;
  color: $dark-brown;

  @include media-up(md) {
    left: rem(-8px);
  }

  @include media-up(lg) {
    left: 0;
  }

  &__icon {
    @include size(rem(8px));

    @include media-up(xl) {
      @include size(rem(10px));
    }
  }
}

.chocolate {
  display: grid;
  flex-grow: 1;
  grid-template-columns: repeat(3, 1fr);
  gap: rem(1px) rem(5px);
}
</style>
