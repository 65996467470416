<template>
  <button ref="button" type="button" @click="toggleTile">
    <TileIcon class="icon" :class="{ 'icon--playing': isPlaying }" />
    <span v-if="isPlaying" class="text"
      >C<sub class="id">{{ id }}</sub></span
    >
  </button>
</template>

<script lang="ts" setup>
import { gsap } from 'gsap'

import TileIcon from '@/assets/icons/beat-machine-chunk.svg?component'

defineProps<{
  isPlaying?: boolean
  id: number
}>()

const emit = defineEmits(['toggle'])

const button = ref<HTMLElement | null>(null)

let playAnimation: gsap.core.Timeline | null = null

useGsapContext(() => {
  playAnimation = gsap
    .timeline({ paused: true })
    .to(button.value, {
      scale: 0.7,
      duration: 0.2,
      onComplete: () => {
        emit('toggle')
      }
    })
    .to(button.value, {
      scale: 1,
      duration: 0.2,
      ease: 'power2.out',
      onReverseComplete: () => {
        emit('toggle')
      }
    })
})

let isReversing = false

const toggleTile = () => {
  if (isReversing) {
    playAnimation?.reverse()
    isReversing = false
  } else {
    playAnimation?.play()
    isReversing = true
  }
}
</script>

<style lang="scss" scoped>
.button {
  position: relative;
}

.icon {
  @include size(100%);
  fill: $yellow;
  stroke: $dark-brown;

  &--playing {
    fill: $dark-brown;
    stroke: $yellow;
  }
}

.text {
  @include center-self;
  @include font-size(
    (
      xs: 12px,
      lg: 15px
    ),
    1.66
  );
  font-weight: 600;
  color: $yellow;
}

.id {
  bottom: -0.15em;
  font-size: 80%;
}
</style>
