<template>
  <canvas
    ref="canvas"
    v-element-visibility="isVisible => (isVisible ? resume() : pause())"
    class="canvas"
  />
</template>

<script lang="ts" setup>
import { vElementVisibility } from '@vueuse/components'
import { useElementBounding, useRafFn } from '@vueuse/core'

const canvas = ref<HTMLCanvasElement | null>(null)

const { width, height } = useElementBounding(canvas)

let ctx: CanvasRenderingContext2D | null = null

const props = withDefaults(
  defineProps<{
    lineWidth?: number
    color?: string
    amplitude?: number
    frequency?: number
    speed?: number
  }>(),
  {
    lineWidth: 3,
    color: '#4B291D',
    amplitude: 5,
    frequency: 0.2,
    speed: 0.009
  }
)

watch([width, height], () => {
  if (!canvas.value) return
  ctx = canvas.value.getContext('2d')
  if (!ctx) return

  canvas.value.width = width.value
  canvas.value.height = height.value

  ctx.lineWidth = props.lineWidth
  ctx.lineCap = 'round'
  ctx.strokeStyle = props.color
})

let phase = 0

const { pause, resume } = useRafFn(({ delta }) => {
  if (!canvas.value || !ctx) return

  ctx.clearRect(0, 0, canvas.value.width, canvas.value.height)

  ctx.beginPath()
  ctx.moveTo(0, canvas.value.height / 2)

  for (let x = 0; x < canvas.value.width; x++) {
    const y = props.amplitude * Math.sin(props.frequency * x + phase)
    ctx.lineTo(x, y + canvas.value.height / 2)
  }

  ctx.stroke()

  phase += 0.009 * delta
})
</script>

<style lang="scss" scoped>
.canvas {
  width: rem(102px);
  height: rem(15px);

  @include media-up(lg) {
    width: rem(145px);
  }
}
</style>
