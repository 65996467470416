<template>
  <div class="tiles">
    <AnimatedRive
      v-for="index in 4"
      :key="index"
      class="rive"
      :autoplay="index !== 2"
      :src="`/rive/four-tiles-${index}.riv`"
    />
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.tiles {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.rive {
  &:nth-child(1) {
    grid-area: 3 / 2;
    /* TODO Replace with global styles */
    background-color: #313131;
  }

  &:nth-child(2) {
    grid-area: 2 / 2;
    /* TODO Replace with global styles */
    background-color: #9c5b23;
  }

  &:nth-child(3) {
    grid-area: 1 / 2;
    /* TODO Replace with global styles */
    background-color: #4a70b3;
  }

  &:nth-child(4) {
    grid-area: 3 / 1;
    /* TODO Replace with global styles */
    background-color: #efe7b6;
  }

  &:nth-child(n) {
    aspect-ratio: 1/1;

    @include media-up(md) {
      grid-area: auto;
    }
  }
}
</style>
