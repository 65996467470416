<template>
  <div class="container" @pointermove="onMouseMove">
    <slot />
    <canvas ref="canvas" class="canvas" />
  </div>
</template>

<script lang="ts" setup>
import { useElementBounding, useRafFn } from '@vueuse/core'

const canvas = ref<HTMLCanvasElement | null>(null)

const { top, left, width, height } = useElementBounding(canvas)

let ctx: CanvasRenderingContext2D | null = null
let mouseX = 0
let mouseY = 0
let trailX = 0
let trailY = 0

const onMouseMove = (e: MouseEvent) => {
  mouseX = e.x - left.value
  mouseY = e.y - top.value
}

watch([width, height], () => {
  if (!canvas.value) return
  ctx = canvas.value.getContext('2d')
  if (!ctx) return

  canvas.value.width = width.value
  canvas.value.height = height.value

  ctx.lineWidth = 100
  ctx.lineCap = 'round'
  ctx.lineJoin = 'round'

  ctx.fillStyle = 'rgba(0,0,0,1)'
  ctx.fillRect(0, 0, canvas.value.width, canvas.value.height)
})

const trailBuffer: [number, number][] = []

useRafFn(({ delta }) => {
  if (!canvas.value || !ctx || (mouseX === 0 && mouseY === 0)) return

  ctx.globalCompositeOperation = 'lighter'
  ctx.fillStyle = `rgba(0,0,0,${0.0004 * delta})`
  ctx.fillRect(0, 0, canvas.value.width, canvas.value.height)

  const trailSpeed = 0.005 * delta

  trailX = trailX + trailSpeed * (mouseX - trailX)
  trailY = trailY + trailSpeed * (mouseY - trailY)

  trailBuffer.push([trailX, trailY])
  if (trailBuffer.length > 10) trailBuffer.shift()

  ctx.globalCompositeOperation = 'destination-out'
  ctx.strokeStyle = 'rgba(255,255,255,0.5)'
  ctx.beginPath()
  trailBuffer.forEach(([x, y]) => ctx?.lineTo(x, y))
  ctx.lineTo(trailX, trailY)
  ctx.stroke()
})
</script>

<style lang="scss" scoped>
.container {
  @include size(100%);
  position: relative;
  touch-action: none;

  & * {
    @include fill-space;
  }
}

.canvas {
  @include recolor($navy-blue-default, $navy-blue-filter);
  pointer-events: none;
}
</style>
